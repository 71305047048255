


.dlTimeInfo {
    position: relative;
}

.dlTimeInfo .from {
    position: absolute;
    left: 1em;
    top: 0em;
}

.dlTimeInfo .to {
    position: absolute;
    left: 1em;
            bottom: 0px;
    color: #b2b2b2;
}

.dlTimeInfo .bill {
    position: absolute;
    right: 1em;
    bottom: 1.5em;
    color: #b2b2b2;
    font-family: monospace;
    color: white;
    font-weight: bold;
    background-color: #4eb7e6;
    padding-left: 0.5em;
    padding-right: 0.5em;
}


.dlTimeInfo .weekid {
    position: absolute;
    font-family: monospace;
    top: 1.5em;
    right: 1em;
    color: #b2b2b2;

}

.weekRow {
    background-color: #b7ece7;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    min-height: 5em;
}

.weekRow.otherMonth {
    background-color: #c3e4ff;

}

.valueDef {
    display: inline-block;
    margin-right: 0.5em;
    min-width: 8em;
}

.valueDef .value {
    margin-right: 0.3em;
    font-family: monospace;
    font-size: 1.2em;
    font-weight: bold;
}

.valueDef .desc {
    color: #707070;
    font-style: italic;
}


