


.clLogger {
    font-family: monospace;
    color: silver;
}


.clLogger .clLogLine {

}

.clLogger.show  {
    display: block;
    text-align: right;
}

.clLogger.hide {
    display: block;
    text-align: right;
}