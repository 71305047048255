


.fmUnsaved {
    color: white;
    background-color: red;
    font-family: monospace;
    font-size: 1em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    border-radius: 1em;
}