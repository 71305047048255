
.es_svgplane{
    user-select: none;
    position: fixed;
    left: 0pt;
    right: 0pt;
    top: 0pt;
    bottom: 0pt;
}

.es_svgplane .es_border {
    stroke: red;
    stroke-width: 0;
    fill:url(#borderGradient)
}


.es_svgplane .es_line {
    stroke-width: 1;
    stroke: #ffffff73;
}

.es_svgplane .es_text_load_km {
    fill: white;
    /* stroke: #ffffff73; */
    font-family: sans-serif;
    font-size: 28px;
}



.es_svgplane .es_curr_bar {
    stroke-width: 0;
    stroke: #0f5d9b;
    fill: url(#wayGradient);
}

.es_svgplane .es_line.es_line_current
{
    stroke-width: 10;
    stroke: #cbd062a8;
}

.es_svgplane .es_curr_barload
{
    /* font-family: sans-serif; */
    /* font-size: 21px; */
    fill:url(#beamGradient);
}



.es_svgplane .es_text_pw
{
    font-family: sans-serif;
    font-size: 39px;
    fill: #ffda00;
    stroke: black;
    stroke-width: 0.5;
}


.es_svgplane .es_text_desc
{
    font-family: sans-serif;
    font-size: 21px;
    fill: #0000008f;
}

.es_svgplane .es_text.es_text_current
{
    stroke-width: 2px;
    stroke: #3f51b5;
    fill: #03a9f4;
    font-size: 86px;
}

.es_svgplane .es_percinfo
{
    stroke-width: 1;
    stroke: #0000008f;
}

.es_svgplane .es_text_per
{
    fill: #000000b5;
    font-size: 25px;
    font-family: sans-serif;
    font-weight: bold;
}



.es_svgplane .es_text {
    font-size: 35px;
    stroke: black;
    fill: white;
    stroke-width: 0px;
}

.debuginfo {
    position: fixed;
    top: 0pt;
    left: 0pt;
}

.es_manager {
    position: fixed;
    bottom: 5pt;
    left: 0pt;
    right: 5pt;
    text-align: right;
}