


.dbBox .dbBody {
    position: relative;
    padding: 2em;
}

.dbBox.hasTable .dbBody {
    padding-right: 0.1em;
}
.dbBox.tabled{
    border: 0pt;
    margin-bottom: 0.5em;
}

.dbBox.inline{
    border: 0pt;
    margin-bottom: 0.5em;
    display: inline-block;
}


.dbBox.tabled .dbBody{
    position: relative;
    padding: 0em;
    border: 0pt;
    padding-right: 3em;
}


.dbControls {
    position: absolute;
    right: -0.05em;
    top: 0.25em;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

button.dataBoxBtn.btn:hover {
    opacity: 1;
}

button.dataBoxBtn.btn {
    opacity: 0.5;
    padding: 0pt;
    border: 0pt;
}

